import { Flow } from 'signup-constants/Flow';
import { Integration } from 'signup-constants/Integration';
import { SignupAnchor } from './SignupAnchor';
import * as Decorators from './decorators';
import { getURLSearchParams } from './getURLSearch';
import { validateRequiredQueryParams } from './checkParamsUtils';
const DEFAULT_CONFIG = {
  debug: false
};
export class SignupUrlGenerator {
  /**
   * Holds the selector that will be used to find links on the page
   * when this is used on the standalone page mode.
   *
   * @static
   * @memberof SignupUrlGenerator
   */

  /**
   * Holds the singleton instance of this generator.
   *
   * @static
   * @memberof SignupUrlGenerator
   */

  /**
   * Holds the customized configuration when using the setConfig method.
   *
   * @static
   * @memberof SignupUrlGenerator
   */

  /** Holds the object containing all decorators */

  /** Holds a flag that indicates when the bootstrap processed all anchors */

  /** Holds all valid signup types */

  /**
   * Sets the customized configuration.
   */
  static setConfig(customConfig) {
    SignupUrlGenerator.config = Object.assign({}, DEFAULT_CONFIG, customConfig);
  }

  /**
   * Returns a singleton instance for this class.
   */
  static getInstance() {
    if (!SignupUrlGenerator.instance) {
      SignupUrlGenerator.instance = new SignupUrlGenerator();
      SignupUrlGenerator.instance.bootstrap();
    }
    return SignupUrlGenerator.instance;
  }

  /**
   * Creates an instance of SignupUrlGenerator.
   */
  constructor(flowOrIntegration = Flow.Crm, options = {
    query: {},
    env: 'prod'
  }) {
    this.decorators = {
      [Flow.Academy]: Decorators.AcademyTypeDecorator,
      [Flow.AcademyEmbedded]: Decorators.AcademyEmbeddedTypeDecorator,
      [Flow.AssetProvider]: Decorators.AssetProviderTypeDecorator,
      [Flow.ClassroomTraining]: Decorators.ClassroomTrainingTypeDecorator,
      [Flow.CmsDevelopers]: Decorators.CmsDevelopersTypeDecorator,
      [Flow.CmsFree]: Decorators.CmsFreeTypeDecorator,
      [Flow.Connect]: Decorators.ConnectTypeDecorator,
      [Flow.Crm]: Decorators.CRMTypeDecorator,
      [Flow.Developers]: Decorators.DevelopersTypeDecorator,
      [Flow.DirectoryListing]: Decorators.DirectoryListingTypeDecorator,
      [Flow.HubspotForStartups]: Decorators.HubSpotForStartupsTypeDecorator,
      [Flow.HubSpotPartners]: Decorators.HubSpotPartnersTypeDecorator,
      [Flow.Integrations]: Decorators.IntegrationsTypeDecorator,
      [Flow.Marketing]: Decorators.MarketingTypeDecorator,
      [Flow.Purchase]: Decorators.PurchaseTypeDecorator,
      [Flow.Sales]: Decorators.SalesTypeDecorator,
      [Flow.Service]: Decorators.ServiceTypeDecorator,
      [Flow.FbCrm]: Decorators.FacebookCrmTypeDecorator,
      [Flow.SolutionsProvider]: Decorators.SolutionsProviderTypeDecorator,
      [Flow.Trial]: Decorators.TrialTypeDecorator,
      [Integration.Wordpress]: Decorators.WordpressTypeDecorator,
      [Integration.Wpforms]: Decorators.WpformsTypeDecorator,
      [Integration.Shopify]: Decorators.ShopifyTypeDecorator,
      [Integration.Typeform]: Decorators.TypeformTypeDecorator,
      [Integration.ShopifyEmbedded]: Decorators.ShopifyTypeDecorator,
      [Flow.Microapp]: Decorators.MicroappTypeDecorator,
      [Flow.Commerce]: Decorators.CommerceTypeDecorator,
      [Flow.EngageAI]: Decorators.EngageAITypeDecorator
    };
    this.processed = false;
    this.validSignupTypes = [...Object.values(Flow), ...Object.values(Integration)];
    this.flowOrIntegration = flowOrIntegration;
    this.options = options;
  }

  /**
   * Checks if the given signup type is valid.
   * @param signupType The signup type to generate the url for
   * @returns True if the signup type is valid. False otherwise.
   */
  isValidSignupType(signupType) {
    return this.validSignupTypes.includes(signupType);
  }

  /**
   * Generates the signup url based on the given flow and query.
   * @param flow The flow to generate the url for.
   * @param query The query to add to the url.
   * @returns The signup url.
   */
  getSignupUrl(flow = this.flowOrIntegration, query = this.options.query) {
    const appPrefix = this.options.isLocal ? 'local' : 'app';
    const base = `https://${appPrefix}.${this.options.env === 'qa' ? 'hubspotqa' : 'hubspot'}.com`;
    let url = `${base}/signup-hubspot`;
    if (flow && this.isValidSignupType(flow)) {
      url = `${base}/signup/${flow}`;
      if (flow in this.decorators) {
        const decorator = this.decorators[flow];
        url = `${base}/${decorator.url()}`;
        query = Object.assign({}, query, decorator.query());
      }
    }
    const UrlSearchParams = getURLSearchParams();
    const searchParams = new UrlSearchParams(query);
    const searchString = searchParams.toString();
    validateRequiredQueryParams(flow, searchString);
    return `${url}${searchString && `?${searchString}` || ''}`;
  }

  /**
   * Logs a message if the debug is enabled.
   * @param message The message to log.
   * @param args Any arguments to be added to the message log.
   */
  log(message, ...args) {
    if (SignupUrlGenerator.config.debug) {
      console.info(`[SignupUrlGenerator] ${message}`, ...args);
    }
  }

  /**
   * Creates a new SignupAnchor instance.
   * @param {HTMLAnchorElement} anchor The anchor element to create the instance for.
   * @returns A SignupAnchor instance.
   */
  createAnchor(anchor) {
    return SignupAnchor.create(anchor, this);
  }

  /**
   * Gets all anchors with the target selector.
   * @returns An array of anchor elements.
   */
  getAnchors() {
    return document.querySelectorAll(SignupUrlGenerator.ANCHOR_SELECTOR);
  }

  /**
   * Process signup anchors and mark this instance as processed.
   */
  processAnchors() {
    if (!this.processed) {
      this.getAnchors().forEach(anchor => {
        this.createAnchor(anchor);
      });
    }
    this.processed = true;
  }

  /**
   * Gets a signup url based on the constructed parameters.
   */
  getUrl() {
    return this.getSignupUrl();
  }

  /**
   * Bootstraps the link transformation.
   */
  bootstrap() {
    window.addEventListener('DOMContentLoaded', () => {
      this.processAnchors();
    });
  }
}
SignupUrlGenerator.ANCHOR_SELECTOR = 'a[data-signup-type]';
SignupUrlGenerator.instance = null;
SignupUrlGenerator.config = DEFAULT_CONFIG;