/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import PreviewAPI from '../api/PreviewAPI';
import { ActionTypes } from '../constants/AppConstants';
const previewAPI = new PreviewAPI();
export default {
  previewWebsite(websiteUrl) {
    return dispatch => {
      dispatch({
        type: ActionTypes.FETCH_PREVIEW_STARTED
      });
      previewAPI.getPreview(websiteUrl, 'DESKTOP').then(response => dispatch({
        type: ActionTypes.DESKTOP_WEBSITE_PREVIEWED,
        payload: response.responseJSON
      }), () => dispatch({
        type: ActionTypes.DESKTOP_WEBSITE_PREVIEWED_ERROR
      }));
      previewAPI.getPreview(websiteUrl, 'MOBILE').then(response => {
        dispatch({
          type: ActionTypes.MOBILE_WEBSITE_PREVIEWED,
          payload: response.responseJSON
        });
      }, () => dispatch({
        type: ActionTypes.MOBILE_WEBSITE_PREVIEWED_ERROR
      }));
    };
  }
};