'use es6';

import API from './API';
const apiUrl = 'websitegrader/v2/preview';
const timeout = 30000;
const DESKTOP_SCREENSHOT_WIDTH = 1500;
const DESKTOP_SCREENSHOT_HEIGHT = 800;
const MOBILE_SCREENSHOT_WIDTH = 375;
const MOBILE_SCREENSHOT_HEIGHT = 667;
const POLL_INTERVAL = 2000; // 2 seconds between polls
const MAX_POLL_ATTEMPTS = 15; // 30 seconds total with 2 second intervals

const DIMENSIONS = {
  DESKTOP: {
    width: DESKTOP_SCREENSHOT_WIDTH,
    height: DESKTOP_SCREENSHOT_HEIGHT
  },
  MOBILE: {
    width: MOBILE_SCREENSHOT_WIDTH,
    height: MOBILE_SCREENSHOT_HEIGHT
  }
};
export default class extends API {
  pollForResult(url, options) {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      const poll = () => {
        if (attempts >= MAX_POLL_ATTEMPTS) {
          reject(new Error('Preview generation timed out'));
          return;
        }
        this.makeGetRequest(url, options).then(response => {
          if (response.status === 200) {
            resolve(response);
          } else if (response.status >= 400) {
            reject(new Error('Preview generation failed'));
          } else {
            attempts++;
            setTimeout(poll, POLL_INTERVAL);
          }
        }).catch(reject);
      };
      poll();
    });
  }
  getPreview(websiteUrl, deviceType = 'DESKTOP') {
    const {
      width,
      height
    } = DIMENSIONS[deviceType];
    return this.makePostRequest(apiUrl, {
      data: {
        width,
        height,
        deviceType,
        domain: websiteUrl
      },
      timeout
    }).then(response => {
      return this.pollForResult(`${apiUrl}/${response.previewId}`, {
        timeout
      });
    });
  }
}